<template>
	<div class="page">
		<div class="block20"></div>
		<!-- 栏目菜单 -->
		<div class="huaxue_home_type w1239">
			<div class="title fontsize38">{{curObj.name || typeName}}</div>
		</div>
		<div class="block10"></div>
		<div class="deal_notice_box w1239" v-if="curObj.show=='info'">
			<div class="notice_item pointer" v-for="(item,index) in dataList" :key="index" @click="projectDetil(index)">
				<div class="img"><img src="../assets/static/norice_icon.png"></div>
				<div class="title fl clamp fontsize18">{{item.title}}</div>
				<div class="timer fr fontsize18">{{item.releaseTime || item.timer}}</div>
			</div>
			<!-- <div class="clear"></div> -->
			<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
			<div class="block30"></div>
			<div class="w1239 phonenone" style="text-align:right">
			    <el-pagination
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next, jumper"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="w1239 pcnone" style="text-align:right">
			    <el-pagination
				      small
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="block30"></div>
		</div>
		<!-- 图片文字列表 -->
		<div class="huaxue_activity_center_box w1252" v-if="curObj.show=='info_img'">
			<div class="itemobj mb12" v-for="(item,index) in dataList" :key="index"  @click="projectDetil(index)">
				<div class="img relative">
					<img :src="item.imgUrl || require('../assets/static/noimg.png')">
					<img v-if="curObj.type=='pxvideo'&&item.videoUrl" class="play" src="../assets/static/play2.png"/>
				</div>
				<div class="policy_con" v-if="curObj.type=='pxvideo'">
					<div class="title_video clamp2 fontsize16">{{item.title}}</div>
					<div class="timediv fontsize14">
						<div class="text clamp">主讲人：{{item.authorInfo}}</div>
					</div>
					<div class="positiondiv fontsize14">
						<div class="text clamp2">简介：{{item.description}}</div>
					</div>
					<div class="more fontsize14">查看详情</div>
				</div>
				<div class="policy_con" v-if="curObj.type=='roadshow'">
					<div class="title_video clamp2 fontsize16">{{item.title}}</div>
					<div class="timediv fontsize14">
						<img class="icon" src="../assets/static/time_icon.png"/>
						<div class="text clamp">{{item.projectTime || ""}}</div>
					</div>
					<div class="positiondiv fontsize14">
						<img class="icon" src="../assets/static/position_icon.png"/>
						<div class="text clamp2">{{item.projectAddress || ""}}</div>
					</div>
					<div class="more fontsize14">查看详情</div>
				</div>
			</div>
			<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
			<div class="block30"></div>
			<div class="w1239 phonenone" style="text-align:right">
			    <el-pagination
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next, jumper"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="w1239 pcnone" style="text-align:right">
			    <el-pagination
				      small
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="block20"></div>
		</div>
		<!-- 专题类列表 -->
		<div class="huaxue_specialinfo_box w1260" v-if="curObj.show=='info_img_act'">
			<div class="data_item mb12" v-for="(item,index) in dataList" :key="index" @click="projectDetil(index)">
				<div class="img">
					<img :src="item.imgUrl || require('../assets/static/noimg.png')">
				</div>
				<div class="data_con">
					<div class="title clamp2 fontsize16">{{item.title}}</div>
					<div class="timer fontsize14">{{item.releaseTime || item.createDate}}</div>
				</div>
			</div>
			<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
			<div class="block30"></div>
			<div class="w1239 phonenone" style="text-align:right">
			    <el-pagination
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next, jumper"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="w1239 pcnone" style="text-align:right">
			    <el-pagination
				      small
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="block20"></div>
		</div>
		<!-- 创新服务 -->
		<div class="huaxue_innovate w1260" v-if="curObj.show == 'info_img_line'">
			<div class="innovate_item relative" v-for="(item,index) in dataList" :key="index" @click="projectDetil(index)">
				<div class="img relative fl">
					<img :src="item.imgUrl || '../assets/static/noimg.png'"/>
				</div>
				<div class="innovate_con fr">
					<div class="title fontsize16 clamp">{{item.title}}</div>
					<div class="desc fontsize14 clamp3">{{item.body}}</div>
				    <div class="more fontsize14">查看详情</div>
				</div>
			</div>
			<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
			<div class="block30"></div>
			<div class="w1239 phonenone" style="text-align:right">
			    <el-pagination
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next, jumper"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="w1239 pcnone" style="text-align:right">
			    <el-pagination
				      small
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="block20"></div>
		</div>
		<!-- 专家/精英团队 -->
		<div class="huaxue_team_box w1239" v-if="curObj.show == 'info_img_team'">
			<div class="team_item" v-for="(item,index) in dataList" :key="index">
				<div class="team_con" @click="$util.routerPath('/teamDetail',{uid:item.id})">
					<div class="img">
						<img :src="item.imgUrl || require('../assets/static/noimg.png')"/>
					</div>
					<div class="title fontsize20">{{item.title}}</div>
					<div class="desc fontsize18">{{item.description}}</div>
				</div>
			</div>
			<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
			<div class="block30"></div>
			<div class="w1239 phonenone" style="text-align:right">
			    <el-pagination
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next, jumper"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="w1239 pcnone" style="text-align:center">
			    <el-pagination
				      small
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="block20"></div>
		</div>
		
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			type:"",//类型，tongzhigonggao
			dataList:[],
			currentPage:1,
			pageSize:12,
			total:0,
			typeName:"",//
			typeId:null,//
			whiteList:[
				{
					type:"chanyezixun",//产业资讯
					name:"",
					url:"frontEnd/realTime/getchanyezixun",
					show:"info",//展示类型
					isbody:false,//是否显示部分内容
				},
				{
					type:"hangyedongtai",//行业动态
					name:"行业动态",
					url:"frontEnd/realTime/getHangyedongtai",
					show:"info",
					isbody:false,
				},
				{
					type:"zhengce",
					name:"政策",
					url:"frontEnd/realTime/getZhengce",
					show:"info_img",
					isbody:false,
				},
				{
					type:"roadshow",
					name:"项目路演",
					url:"frontEnd/realTime/getxiangmuluyan",
					show:"info_img",
					isbody:false,
				},
				{
					type:"pxvideo",
					name:"培训学习",
					url:"frontEnd/realTime/getpeixunxuexi",
					show:"info_img",
					isbody:true,
				},
				{
					type:"special",
					name:"知产剧场，轻松一刻",
					url:"frontEnd/realTime/getzhichanjuchang",
					show:"info_img_act",
					isbody:false,
				},
				{
					type:"project",
					name:"项目公开",
					url:"frontEnd/projectServices/getXiangmugongkai",
					show:"info_img",
					isbody:true,
				},
				{
					type:"cxservice",
					name:"创新服务",
					url:"frontEnd/projectServices/getChuangxinfuwu",
					show:"info_img_line",
					isbody:true,
				},
				{
					type:"zjteam",
					name:"专家团队",
					url:"frontEnd/projectServices/getZhuanjiatuandui",
					show:"info_img_team",
					isbody:false,
				},
				{
					type:"zsqingbao",
					name:"知识产权情报",
					url:"frontEnd/patent/getChanquanqingbao",
					show:"info_img_act",
					isbody:false,
				},
				{
					type:"jiaoyigongshi",
					name:"交易公示",
					url:"frontEnd/transaction/getJiaoyixinxi",
					show:"info",
					isbody:false,
				},
				{
					type:"chanquanzixun",
					name:"知识产权资讯",
					url:"frontEnd/index/getChanquanzixun",
					show:"info",
					isbody:false,
				},
				{
					type:"xiangguanzhengce",
					name:"相关政策",
					url:"frontEnd/open-patent/getXiangguanzhengce",
					show:"info",
					isbody:false,
				},
			],
			curObj:{
				type:"",
				name:"",
				url:"",
				show:"info",
				isbody:false,
			},
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.type){
			var type = this.$route.query.type
			let obj = this.whiteList.find((item) => { //这里的options就是上面遍历的数据源
				return item.type == type; //筛选出匹配数据
			});
			if(obj){
				this.type = type
				this.curObj = obj
				if(this.$route.query.uid){
					this.typeId = this.$route.query.uid
				}
				if(this.$route.query.typeName){
					this.typeName = this.$route.query.typeName
				}
				this.getdataList()
			}else{
				this.$util.routerPath("/404")
			}
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		projectDetil(index){
			var obj = this.dataList[index]
			var url = obj.outUrl
			if(obj.outType==1){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					this.$router.push({
						name: url
					});
				}
			}else{
				this.$router.push({ path: '/infoDetail', query: {uid: obj.id }})
			}
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.getdataList()
		},
		//获取数据
		getdataList() {
			var _this = this;
			var params = {
				currentPage: this.currentPage,
				pageSize: this.pageSize,
			}
			if(this.typeId){
				params["realTimeTypeId"] = this.typeId
			}
			params["useContents"] = this.curObj.isbody
			this.$http.post(this.curObj.url, params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						if(_this.curObj.isbody){
							// if(item.contents){
							// 	item["body"] = _this.$util.filtersText(item.contents,218)
							// }	
						}
						if(_this.curObj.show=="info"){
							if(item.releaseTime){
								item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
							}
							item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						}
					})
					_this.total = res.data.total
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
